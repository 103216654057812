import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import DaiseeD from '../../../assets/d_daisee.svg'
import Hr from '../../../components/BlogPosts/utils/Hr'
import Container from '../../../components/Container'
import Footer from '../../../components/Footer/Footer'
import Intro from '../../../components/Intro'
import LogoCircle from '../../../components/LogoCircle'
import QuoteBubble from '../../../components/QuoteBubble'
import { Site } from '../../../components/Site'
import { Main } from '../../../components/Styled'
import { SEO } from '../../../SEO'

const quotes = {
  richard:
    'daisee could not be more thrilled to have Kylie as our next CEO. She brings exceptional leadership experience to the table and has repeatedly demonstrated the ability to drive growth and scale for companies working to make the next leap.'
}

const NewCEO: React.FC<PageProps> = props => {
  const images = useStaticQuery(graphql`
    query NewCeo {
      newCeo: file(relativePath: { eq: "ceo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      og: file(relativePath: { eq: "ceo-og.jpg" }) {
        publicURL
      }
      richard: file(relativePath: { eq: "richard.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Site seo={{ ...SEO.newCeo, image: images.og.publicURL }} {...props}>
      <Main>
        <Intro bgColor='purple100'>
          <Container>
            <PageContent>
              <h1 className='heading' style={{ textAlign: 'center' }}>
                daisee Announces New CEO and Bolstering of Management Team
              </h1>
              <Img
                style={{ maxHeight: '25rem' }}
                imgStyle={{ objectFit: 'contain', padding: '1.5rem' }}
                fluid={images['newCeo'].childImageSharp.fluid}
                alt='daisee Executive Leadership Team'
              />
            </PageContent>
          </Container>
        </Intro>
        <Container>
          <PageContent>
            <div className='inner'>
              <DateStyle>Sydney, Australia, 8 March 2022</DateStyle>

              <p>
                daisee, an Australian A.I. software company named as a recent Gartner Cool Vendor, announced today the
                appointment of Dr Kylie de Boer as CEO. Kylie will lead
                daisee in its next phases of development and growth as the company continues its rapid expansion
                capitalising on recent success in the Insurance sector. Kylie has served as daisee’s Chief of Operations
                since 2019 and has enjoyed a diverse career spanning health and technology, notably as CEO Genea, Sun
                Doctors and Southern Sun Pathology. In these roles she has supported organic growth and growth through
                M&A, greenfield, and JV’s. Over the course of her career, she has worked with a wide range of
                stakeholders and equity investors, including private equity. Kylie brings over 15 years of success in
                delivering new and disruptive solutions to market, cost efficiently and at pace.
              </p>
              <p>
                Commenting on the news, <Link to='/our-people/richard-kimber'>Richard Kimber</Link>, daisee Founder,
                said,
              </p>
              <QuoteBubble
                quote={quotes['richard']}
                author='Richard Kimber - Founder and Director daisee'
                bg='purple100'
                imgSrc={images['richard'].childImageSharp.fluid}
                style={{ marginTop: '3rem' }}
              />
              <p>
                Kylie added{' '}
                <q className='italic'>
                  I am really excited about the opportunity to continue to develop daisee’s potential and this next
                  phase of growth. I know the team and the Shareholders well and am confident we are all aligned to
                  deliver on this amazing product
                </q>
              </p>
              <p>
                Kylie’s appointment was supported by key investor Rajeev Gupta of{' '}
                <a href='https://aliumcap.com/'>Alium Capital Management</a>{' '}
                <q className='italic'>
                  The positive momentum daisee has built in the last 12 months now needs acceleration in penetration and
                  execution. At the same time, we wanted someone who understood daisee and its world-class team. I'm
                  confident we've found the perfect fit with Kylie, and I'm delighted to be working with her.
                </q>
              </p>
              <p>
                Since its formation in 2017, daisee has been led by CEO and co-founder Richard Kimber who will move to
                the Board as a Founder Director. Richard will continue to support the company with his significant
                experience in technology and financial services from CEO and executive roles at OFX Group, Google, ANZ
                Bank; and as chairman of Fintech hub, Stone & Chalk.
              </p>
              <p>
                Additionally, daisee welcomes <Link to='https://www.linkedin.com/in/attifrizk/'>Attif Rizk</Link> as Head of Customer
                Success and Partnering. He has over 25 years’ experience delivering business solutions to Australian and
                Global banks, telcos, government, and Health organisations. He has had a career within tier one business
                and technology consulting and systems integration practices including Accenture, Deloitte, Avanade, IBM
                and Capgemini. Most recently Attif was the Director of Professional Services at{' '}
                <a href='https://www.salesforce.com/au/'>Salesforce</a>.
              </p>
              <p>
                <Link to='/discovery'>daisee’s solutions</Link> help organizations create practical business value today
                from day one with pre-trained software that is incredibly flexible and easy to deploy across any
                telephony system. daisee solutions are tailored for various industries, particularly Insurance and
                Financial Services with high regulatory and compliance requirements. daisee’s deep domain knowledge,
                data science teams and industry specific functionality help large and small companies deliver
                exceptional customer service across 100% of their contact centre calls.
              </p>
              <Hr />
              <LogoCircle file={DaiseeD} alt='daisee logo' />
              <br />
              <b>About daisee</b>
              <br />
              <br />
              <p>
                daisee builds technology that empowers people. daisee provides world-leading automated quality
                management of customer interactions, powered by artificial intelligence, speech and text analytics.
                Enabling you to see far beyond words alone, surfacing the underlying emotion hidden deep within your
                customer interactions – what your customers are really saying, thinking, and feeling. daisee helps
                people solve problems by making interactions simple and smart so they can have a more significant
                impact, be more productive, and be better at what they do.
              </p>
              <p className='small'>Founded in 2017, daisee operates globally in Australia, USA, and New Zealand.</p>
            </div>
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const DateStyle = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${props => props.theme.colors.grey700};
`

const PageContent = styled.div`
  .heading {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
    max-width: 680px;
  }

  ul,
  li {
    list-style-type: disc;
    margin: 0 1.5rem;
  }

  .share {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
  }

  .isPast {
    padding: 6rem 0;
    margin: 0 auto;
    text-align: center;

    .over {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.purple};
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .link {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }

  h2 {
    font-weight: 400;
    font-size: 1.125rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    align-items: start;

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  }

  .copy {
    display: grid;
    gap: 1.5rem;
    height: fit-content;

    .register-link {
      margin: 1.5rem 0;
    }
  }

  .italic {
    font-style: oblique;
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: fit-content;
    gap: 1.5rem;

    .bold {
      font-weight: 600;
    }
  }

  .inner {
    max-width: 680px;
    margin: 0 auto;

    p {
      line-height: 1.6;
    }

    .heading {
      margin-top: 3rem;
    }

    * {
      margin-bottom: 1.5rem;
    }

    [class*='LogoCircle'],
    [class*='QuoteBubble__Author'],
    [class*='QuoteBubble__QuoteWrapper'],
    [class*='QuoteBubble__ImgWrapper'] {
      margin-bottom: 0;

      img {
        margin-bottom: 0;
      }
    }

    blockquote {
      margin-bottom: 0;
    }

    a {
      color: ${({ theme }) => theme.colors.purple};
      text-decoration: underline;
    }

    p,
    b {
      font-size: 1.125rem;
      color: ${({ theme }) => theme.colors.grey700};
    }

    .small {
      font-size: 0.875rem;
    }

    span {
      z-index: 1;
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      p {
        font-size: 1rem;
      }
    }
  }

  .last {
    padding-bottom: 6rem;
  }
`

export default NewCEO
